<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img width="200" height="50" src="@/assets/images/logo.png" alt="">
        </b-link>
        <b-card-title class="mb-1">
          {{ $t('CheckOTP') }}
        </b-card-title>
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2" @submit.prevent="CheckCode">
            <!-- OTPCode -->
            <b-form-group :label="$t('OTPCode')" label-for="OTPCode">
              <validation-provider #default="{ errors }" name="OTPCode" rules="required|min:4">
                <b-form-input id="OTPCode" v-model="OTPCode" :state="errors.length > 0 ? false : null"
                  name="register-OTPCode" placeholder="4545" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button variant="primary" block type="submit" :disabled="!validateForm">
              {{ $t('CheckCode') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
    </div>
    <vs-popup title="" :active.sync="ShowSuccessPopup">
      <SuccessPopup :Message="$t('RegisterSuccsessMessage')" v-if="ShowSuccessPopup"
        @CloseSeccussPopup="CloseSeccussPopup" @closePop="this.$router.push('/pages/login')"></SuccessPopup>
    </vs-popup>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import {
  BButton, BCard, BCardText, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BInputGroup, BInputGroupAppend, BLink, BRow
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SuccessPopup from "./SuccessPopup.vue"
export default {
  components: {
    VuexyLogo,
    SuccessPopup,
    // BSV
    BRow,
    BCol,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      baseURL: domain,
      OTPCode: '',
      ShowSuccessPopup: false,
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    validateForm() {
      return (
        this.OTPCode != ""
      );
    },
  },
  methods: {
    CloseSeccussPopup() {
      debugger
      this.ShowSuccessPopup = false;
      //this.$router.push({ name: "auth-register-v2" });
      this.$router.push({ name: "registerNewUser", params: { Id: this.$route.params.Id } });

    },
    CheckCode() {
      var PhoneNumber = this.$route.params.Id;
      debugger
      const payload = {
        userDetails: {
          OTPCode: this.OTPCode,
          PhoneNumber: PhoneNumber
        },
      };
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("auth/CheckOTPCode", payload)
        .then((res) => {
          debugger;
          this.$vs.loading.close();
          this.ShowSuccessPopup = true;
          //var messge = res.response.Message;
          //console.log(messge);
        })
        .catch((error) => {
          debugger;
          this.$vs.loading.close();
          this.$vs.notify({
            title: "regstration Attempt",
            text: "error in check OTP code",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });

          debugger;
          console.log(error);
        });
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style>
.material-icons {
  font-family: "Material Icons" !important;
}

.material-icons :lang(en) {
  font-family: unset;
}

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}

.is_valid:before {
  width: 50%;
}

li {
  /* margin-bottom: 8px; */
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>