<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="popSucc" class="succesuss">
    <div slot="no-body">
      <div class="justify-center items-center ">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <div class="p-5">
            <div class="logo text-center w-full">
              <img width="200" height="50" class="TextLogo" alt="" src="@/assets/images/logo.png" />
            </div>
            <div class="mt-4 text-center">
              <h5 class=" mt-2  text-center" style="font-color:black">{{ Message }} </h5>
            </div>
            <div style="text-align: center">
              <vs-button style=" margin-top: 2rem" @click="$emit('CloseSeccussPopup')">{{ $t("ok") }}</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    }
  },
  components: {},
  computed: {
  },
  created() {

  },
};
</script>
<style lang="scss">
#popSucc .succesuss {
  width: 25rem;
  margin: auto;
}

#popSucc .logo {


  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
</style>


